import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { database } from "../../firebase";

const Wrapper = styled(Paper)`
  margin: 10px;
  min-height: 800px;
`;
const NotesPage = props => {
  const [editorState, setEditorState] = useState();
  useEffect(() => {
    database
      .collection("notes")
      .doc("note")
      .get()
      .then(noteDoc => {
        if (noteDoc.data().content) {
          setEditorState(
            EditorState.createWithContent(convertFromRaw(JSON.parse(noteDoc.data().content)))
          );
        }
      });
  }, []);
  useEffect(() => {
    if (editorState) {
      database
        .collection("notes")
        .doc("note")
        .set({ content: JSON.stringify(convertToRaw(editorState.getCurrentContent())) });
    }
  }, [editorState]);
  if (!editorState) {
    return <>Loading</>;
  }
  return (
    <Wrapper>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        style={{ height: "100%" }}
      />
    </Wrapper>
  );
};

export default NotesPage;
