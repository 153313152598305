// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDQh4G_jxghlfS9jinBzdo_P3cO1UE7bSA",
  authDomain: "budget-tracker-83ff4.firebaseapp.com",
  projectId: "budget-tracker-83ff4",
  storageBucket: "budget-tracker-83ff4.appspot.com",
  messagingSenderId: "967831800894",
  appId: "1:967831800894:web:35a3db3ec2cc4da9f6d56f",
  measurementId: "G-59PXCSGDYR",
};
