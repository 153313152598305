import React from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import useCollection from "../../hooks/useCollection";
import useDocument from "../../hooks/useDocument";
import CustomDateLineGraph from "../graphs/CustomDateLineGraph";
import ExpectedVsActualBarGraph from "../graphs/ExpectedVsActualBarGraph";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 95vw;
  align-items: center;
`;

const OuterGraphWrapper = styled(Paper)`
  width: 95%;
  padding: 10px 20px;
  margin: 10px 0;
  color: black;
`;

const GraphWrap = styled.div`
  width: 100%;
  height: 500px;
  color: black;
`;

const GraphPage = () => {
  const [expenses, expensesLoading, expensesError] = useCollection("expenses");
  const [constants] = useDocument("config", "constants");

  if (expensesLoading || expensesError || !constants.expenseCategories) {
    return null;
  }

  return (
    <Wrapper>
      <OuterGraphWrapper>
        <h2>Custom Date Individual Line</h2>
        <GraphWrap>
          <CustomDateLineGraph expenses={expenses} constants={constants} />
        </GraphWrap>
      </OuterGraphWrapper>
      <OuterGraphWrapper>
        <h2>Monthly Expected Vs. Actual</h2>
        <GraphWrap>
          <ExpectedVsActualBarGraph expenses={expenses} constants={constants} />
        </GraphWrap>
      </OuterGraphWrapper>
    </Wrapper>
  );
};

export default GraphPage;
