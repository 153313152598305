import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { getCategoryFigures, getExpenseRows } from "../../helpers";
import useDocument from "../../hooks/useDocument";
import useCollection from "../../hooks/useCollection";

const Wrapper = styled(Paper)`
  height: 90%;
  max-width: calc(100vw - 10px);
  min-width: calc(90% - 10px);
  padding: 10px;
  margin-top: 20px;
`;

const ExpenseListPage = props => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [expenses, expensesLoading] = useCollection("expenses");
  const [constants, constantsLoading] = useDocument("config", "constants");

  const rowData = useMemo(() => {
    return getExpenseRows(expenses, constants.expenseCategories, selectedDate);
  }, [expenses, constants, selectedDate]);
  return (
    <Wrapper>
      <DatePicker
        openTo="month"
        views={["year", "month"]}
        label="Year and Month"
        value={selectedDate}
        onChange={setSelectedDate}
      />
      {expensesLoading || constantsLoading || !constants.expenseCategories ? (
        `Loading`
      ) : (
        <TableContainer component={Paper} style={{ marginTop: 10 }} elevation={3}>
          <Table aria-label={`Expenses for ${format(selectedDate, "MMM yyyy")}`}>
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Category</TableCell>
                  <TableCell align="right">Cost</TableCell>
                  <TableCell align="right">Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {format(row.date, "MMM dd")}
                    </TableCell>
                    <TableCell align="right">{row.category}</TableCell>
                    <TableCell align="right">${row.cost}</TableCell>
                    <TableCell align="right">{row.note}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          </Table>
        </TableContainer>
      )}
    </Wrapper>
  );
};

export default ExpenseListPage;
