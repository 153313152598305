import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import React, { useMemo, useState } from "react";
import { eachDayOfInterval, format, startOfMonth } from "date-fns";
import { groupBy, orderBy } from "lodash";
import { DatePicker } from "@material-ui/pickers";

const CustomDateLineGraph = props => {
  const { constants, expenses } = props;
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const sortedExpenses = useMemo(() => {
    if (!constants || !expenses || !constants.expenseCategories) {
      return [];
    }
    const fullDates = eachDayOfInterval({ start: startDate, end: endDate }).map(date => ({
      date,
      cost: 0,
      category: 0,
    }));
    const filtered = [
      ...expenses.filter(expense => {
        const date = expense.date.toDate();
        return date >= startDate && date <= endDate;
      }),
      ...fullDates,
    ];
    const sorted = orderBy(
      filtered,
      [expense => (expense.date.toDate ? expense.date.toDate() : expense.date)],
      ["ASC"]
    );

    const grouped = groupBy(sorted, obj =>
      format(obj.date.toDate ? obj.date.toDate() : obj.date, "MMM dd, yyyy")
    );

    const baseCostObj = {};
    constants.expenseCategories.forEach(category => (baseCostObj[category.name] = 0));

    const formatted = Object.keys(grouped).map(date => {
      const costs = { ...baseCostObj };

      grouped[date].forEach(expense => {
        const category = Object.keys(costs)[expense.category];
        if (!isNaN(expense.cost)) {
          costs[category] += parseFloat(expense.cost);
        }
      });
      costs.date = date;
      // costs.amt = parse(date, "MMM dd", new Date());
      return costs;
    });
    return formatted;
  }, [expenses, constants, startDate, endDate]);

  const labelFunc = date => {
    return format(date, "MMM dd, yyyy");
  };

  return (
    <div style={{ width: "100%", height: "calc(100% - 50px)" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
          labelFunc={labelFunc}
        />
        <DatePicker label="End Date" value={endDate} onChange={setEndDate} labelFunc={labelFunc} />
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={sortedExpenses}
          // margin={{
          //   top: 5,
          //   right: 30,
          //   left: 20,
          //   bottom: 5,
          // }}
        >
          {/*<CartesianGrid strokeDasharray="4 4" />*/}
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          {constants.expenseCategories.map(category => (
            <Line type={"monotone"} dataKey={category.name} stroke={category.color} />
          ))}
          {/*<Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />*/}
          {/*<Line type="monotone" dataKey="uv" stroke="#82ca9d" />*/}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomDateLineGraph;
