import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import { Paper } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import Colors from "../../Colors";
import useDocument from "../../hooks/useDocument";
import { database } from "../../firebase";

const Wrapper = styled(Paper)`
  grid-template-columns: 90%;
  padding: 20px 20px;
  width: calc(80% - 20px);
  margin: 10px;
  display: grid;
  grid-auto-flow: row;
  row-gap: 20px;
  background-color: ${Colors.floater};
  justify-content: center;
`;

const getEmptyExpenseObject = () => ({
  date: new Date(),
  category: 0,
  cost: "",
  note: "",
});
const ExpenseInputPage = props => {
  const [expenseObject, setExpenseObject] = useState(getEmptyExpenseObject());
  const [isSending, setSending] = useState(false);
  const [submitError, setSubmitError] = useState();
  const submitNode = useRef();
  const [constants] = useDocument("config", "constants");

  const submit = () => {
    database
      .collection("expenses")
      .doc(uuidv4())
      .set(expenseObject)
      .then(() => {
        setExpenseObject(getEmptyExpenseObject());
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    submitNode.current = mutateFn => {
      setSending(true);
      mutateFn(expenseObject)
        .then(() => {
          setSending(false);
          setExpenseObject(getEmptyExpenseObject());
        })
        .catch(e => {
          setSending(false);
          setSubmitError(e);
        });
    };
  }, [expenseObject]);

  const onFieldChange = update => {
    setExpenseObject({ ...expenseObject, ...update });
  };
  return (
    <Wrapper elevation={3}>
      {submitError}
      {isSending && "Submitting..."}
      <FormControl variant={"filled"}>
        <DatePicker
          value={expenseObject.date}
          onChange={date => onFieldChange({ date })}
          label={"Purchase Date"}
        />
      </FormControl>
      <FormControl variant={"filled"}>
        <InputLabel id={"category-label"}>Category</InputLabel>

        <Select
          labelId="category-label"
          id="category"
          value={expenseObject.category}
          onChange={e => onFieldChange({ category: e.target.value })}
          label={"Category"}
        >
          {constants.expenseCategories?.map((category, index) => (
            <MenuItem key={index} value={index}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Cost"
        value={expenseObject.cost}
        onChange={e => onFieldChange({ cost: e.target.value })}
        variant="filled"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <TextField
        label="Note"
        multiline
        maxRows={4}
        value={expenseObject.note}
        onChange={e => onFieldChange({ note: e.target.value })}
        variant="filled"
      />
      <FormControl variant={"filled"}>
        <Button onClick={submit} variant="filled">
          Submit
        </Button>
      </FormControl>
    </Wrapper>
  );
};

export default ExpenseInputPage;
