import { isSameMonth } from "date-fns";
import { orderBy } from "lodash";

export const getCategoryFigures = (expensesCollection, categories, referenceDate) => {
  if (!expensesCollection || !categories || !referenceDate) {
    return [];
  }
  console.log(expensesCollection);
  const dateFilteredExpenses = expensesCollection.filter(expense =>
    isSameMonth(referenceDate, expense.date.toDate())
  );
  const rows = categories.map((category, index) => {
    const rowObject = { category };
    const categoryExpenses = dateFilteredExpenses.filter(expense => expense.category === index);
    let totalCost = 0;
    categoryExpenses.forEach(expense => {
      if (!isNaN(expense.cost)) {
        totalCost += parseFloat(expense.cost);
      }
    });
    rowObject.cost = totalCost;
    return rowObject;
  });
  console.log(rows);
  return rows;
};

export const getExpenseRows = (expensesCollection, categories, referenceDate) => {
  if (!expensesCollection || !categories || !referenceDate) {
    return [];
  }

  const dateFilteredExpenses = expensesCollection
    .filter(expense => isSameMonth(referenceDate, expense.date.toDate()))
    .map(expense => ({
      ...expense,
      date: expense.date.toDate(),
      category: categories[expense.category].name,
    }));

  return orderBy(dateFilteredExpenses, ["date"], ["desc"]);
};
