import React, { useState } from "react";
import ExpenseInputPage from "./components/pages/ExpenseInputPage";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Colors from "./Colors";
import styled from "styled-components";
import StoreIcon from "@material-ui/icons/Store";
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import MenuIcon from "@material-ui/icons/Menu";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CommentIcon from "@material-ui/icons/Comment";
import GraphPage from "./components/pages/GraphPage";
import MonthlyFiguresPage from "./components/pages/MonthlyFiguresPage";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import BudgetEditorPage from "./components/pages/BudgetEditorPage";
import NotesPage from "./components/pages/NotesPage";
import ExpenseListPage from "./components/pages/ExpenseListPage";

const pages = Object.freeze({
  graphPage: {
    label: "Graphs",
    icon: <EqualizerIcon />,
    component: GraphPage,
    link: "/graphs",
  },
  monthlyFigures: {
    label: "Monthly Figures",
    icon: <CalendarTodayIcon />,
    component: MonthlyFiguresPage,
    link: "/monthly",
  },
  budgetEditor: {
    label: "Budget Editor",
    icon: <EditIcon />,
    component: BudgetEditorPage,
    link: "/editBudget",
  },
  notes: {
    label: "Notes",
    icon: <CommentIcon />,
    component: NotesPage,
    link: "/notes",
  },
  expenseList: {
    label: "Expense List",
    icon: <ListIcon />,
    component: ExpenseListPage,
    link: "/expenseList",
  },
  expenseInput: {
    label: "Expense Input",
    icon: <StoreIcon />,
    component: ExpenseInputPage,
    link: "/",
  },
});

const Wrapper = styled.div`
  background-color: ${Colors.background};
  width: 100vw;
  min-height: 100vh;
`;

const PageWrapper = styled.div`
  height: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const pathName = window.location.pathname;
  const theme = createTheme({
    palette: {
      type: "dark",
    },
  });

  const changePage = page => {
    setDrawerOpen(false);
  };

  return (
    <Router>
      <Wrapper>
        <ThemeProvider theme={theme}>
          <Drawer anchor={"left"} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
            <h1 style={{ marginLeft: 15 }}>Pages</h1>
            <List style={{ width: 250 }}>
              {Object.keys(pages).map((key, index) => (
                <Link to={pages[key].link} style={{ textDecoration: "none", color: "white" }} key={index}>
                  <ListItem button key={key} onClick={() => changePage(key)}>
                    <ListItemIcon>{pages[key].icon}</ListItemIcon>
                    <ListItemText primary={pages[key].label} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Drawer>
          <AppBar position="static">
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">
                {pages[Object.keys(pages).find(key => pages[key].link === pathName)]?.label}
              </Typography>
            </Toolbar>
          </AppBar>
          {/*<FirestoreProvider {...firebaseConfig} firebase={firebase}>*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <PageWrapper>
              <Switch>
                {Object.keys(pages).map(key => (
                  <Route path={pages[key].link} component={pages[key].component} key={key} />
                ))}
              </Switch>
            </PageWrapper>
          </MuiPickersUtilsProvider>
          {/*</FirestoreProvider>*/}
        </ThemeProvider>
      </Wrapper>
    </Router>
  );
}

export default App;
