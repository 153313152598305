import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useMemo, useState } from "react";
import { isSameMonth } from "date-fns";
import { DatePicker } from "@material-ui/pickers";

const ExpectedVsActualBarGraph = props => {
  const { expenses, constants } = props;
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const groupedExpenses = useMemo(() => {
    const filtered = expenses.filter(expense => isSameMonth(expense.date.toDate(), selectedMonth));

    const data = constants.expenseCategories.map(category => ({
      category: category.name,
      Budgeted: category.budgeted,
      Actual: 0,
    }));

    filtered.forEach(expense => {
      if (!isNaN(expense.cost)) {
        data[expense.category].Actual += parseFloat(expense.cost);
      }
    });
    return data;
  }, [expenses, constants, selectedMonth]);

  return (
    <div style={{ width: "100%", height: "calc(100% - 50px)" }}>
      <DatePicker
        openTo="month"
        views={["year", "month"]}
        label="Year and Month"
        value={selectedMonth}
        onChange={setSelectedMonth}
      />
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={groupedExpenses}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/*<CartesianGrid strokeDasharray="3 3" />*/}
          <XAxis dataKey="category" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Budgeted" stackId="a" fill="#8884d8" />
          <Bar dataKey="Actual" stackId="b" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ExpectedVsActualBarGraph;
