import styled from "styled-components";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useMemo, useState } from "react";
import { getCategoryFigures } from "../../helpers";
import { format } from "date-fns";
import useCollection from "../../hooks/useCollection";
import useDocument from "../../hooks/useDocument";

const Wrapper = styled(Paper)`
  height: 90%;
  max-width: calc(100vw - 10px);
  min-width: calc(90% - 10px);
  padding: 10px;
  margin-top: 20px;
`;

const MonthlyFiguresPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [expenses] = useCollection("expenses");
  const [constants] = useDocument("config", "constants");

  const rows = useMemo(() => {
    return getCategoryFigures(expenses, constants.expenseCategories, selectedDate);
  }, [selectedDate, expenses, constants]);

  return (
    <Wrapper>
      <DatePicker
        openTo="month"
        views={["year", "month"]}
        label="Year and Month"
        value={selectedDate}
        onChange={setSelectedDate}
      />
      <TableContainer component={Paper} style={{ marginTop: 10 }} elevation={3}>
        <Table aria-label={`Expenses for ${format(selectedDate, "MMM yyyy")}`}>
          <>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell align="right">Actual Cost</TableCell>
                <TableCell align="right">Budgeted</TableCell>
                <TableCell align="right">Difference</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(row => (
                <TableRow key={row.category.name}>
                  <TableCell component="th" scope="row">
                    {row.category.name}
                  </TableCell>
                  <TableCell align="right">${row.cost}</TableCell>
                  <TableCell align="right">${row.category.budgeted}</TableCell>
                  <TableCell align="right">
                    {row.category.budgeted - row.cost < 0 && `-`}$
                    {Math.abs(row.category.budgeted - row.cost)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default MonthlyFiguresPage;
