import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import useDocument from "../../hooks/useDocument";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import { ChromePicker } from "react-color";
import { database } from "../../firebase";
import {orderBy} from "lodash";

const Wrapper = styled(Paper)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  margin-top: 20px;
`;

const CategoryTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px;
`;
const TileSubWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const getRandomColor = () => {
  return "#" + Math.floor(Math.random()*16777215).toString(16);
}

const getBaseCategory = () => ( {name: "", budgeted: undefined, color: getRandomColor()})

const CategoryTile = props => {
  const { category, onEdit, onDelete } = props;

  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  return (
    <CategoryTileWrapper>
      <TileSubWrap>
        <TextField
          label="Name"
          value={category.name}
          onChange={e => onEdit({ ...category, name: e.target.value })}
        />
        <TextField
          label="Budgeted"
          value={category.budgeted}
          onChange={e => onEdit({ ...category, budgeted: e.target.value })}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </TileSubWrap>
      <TileSubWrap>
        <div
          style={{
            backgroundColor: "rgba(255,255,255, 0.05)",
            position: "relative",
            display: "flex",
            alignContent: "center",
          }}
        >
          <Button
            style={{ color: category.color }}
            onClick={() => setColorPickerOpen(!colorPickerOpen)}
          >
            {category.color}
          </Button>
          {/*<div onClick={() => setColorPickerOpen(!colorPickerOpen)}>Color: {category.color}</div>*/}
          {/*<div style={{ position: "absolute", top: 40, zIndex: 2 }}>*/}
          {/*  {colorPickerOpen && (*/}
          <Modal
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            open={colorPickerOpen}
            onClose={() => setColorPickerOpen(false)}
          >
            <ChromePicker
              color={category.color}
              onChangeComplete={color => onEdit({ ...category, color: color.hex })}
            />
            {/*<Button onClick={() => setColorPickerOpen(false)}>Done</Button>*/}
          </Modal>

          {/*)}*/}
          {/*</div>*/}
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!category.constant}
              onChange={() => onEdit({ ...category, constant: !category.constant })}
              name="checkedA"
            />
          }
          label="Constant"
        />
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </TileSubWrap>
    </CategoryTileWrapper>
  );
};

const BudgetEditorPage = () => {
  const [constants] = useDocument("config", "constants");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (constants?.expenseCategories) {
      setCategories(orderBy(constants.expenseCategories, ["name"], ["asc"]).filter(category => !category.deleted));
    }
  }, [constants]);

  const onEdit = (index, newCategory) => {
    const newCategories = [...categories];
    newCategories[index] = newCategory;
    setCategories(newCategories);
  };

  const submit = () => {
    database
      .collection("config")
      .doc("constants")
      .update({ expenseCategories: categories })
      .catch(e => {console.log(e)});
  };

  // TODO - If its a new category just get rid of it.
  const onDelete = categoryIndex => {
    const updatedCategories = [...categories];
    // console.log(updatedCategories)
    // console.log(categoryIndex)
    // if (categoryIndex >= constants?.expenseCategories.length){
    //   setCategories(updatedCategories.splice(categoryIndex, 1));
    // }
    // else {
      updatedCategories[categoryIndex].deleted = true;
      setCategories(updatedCategories);
    // }

  };

  return (
    <Wrapper>
      <Button variant={"contained"} startIcon={<SaveIcon />} color={"primary"} onClick={submit}>
        Save
      </Button>
      {categories.map((category, index) => {
        return (
          <CategoryTile
            key={index}
            category={category}
            onEdit={newCategory => onEdit(index, newCategory)}
            onDelete={() => onDelete(index)}
          />
        );
      })}
      <Button
        variant={"contained"}
        startIcon={<AddIcon />}
        onClick={() => setCategories([...categories, getBaseCategory()])}
      >
        Add New Category
      </Button>
    </Wrapper>
  );
};

export default BudgetEditorPage;
